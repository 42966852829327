import React from "react"
import { Link,graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSmall from "../components/headerSmall"
import ComparisonTable from "../components/comparisonTable"

export const query = graphql`
    query ($provider: String,$product: String){
      site {
        siteMetadata {
        title,
        year,
        guideyear
        }
      },
      fritidsbatforsikring2020Csv(Provider: {eq: $provider}, Product: {eq: $product}){
        Provider
        Product
        Defaqto_Stjerne
      },
      table: allFritidsbatforsikring2020Csv(sort: {fields: Defaqto_Stjerne, order: DESC}) {
        edges {
          node{
            Provider
            Product
            Defaqto_Stjerne
          }
        }
      }
    }
  `

export default({data}) => (
  <Layout>
    <SEO
      title={data.fritidsbatforsikring2020Csv.Product + " fra " + data.fritidsbatforsikring2020Csv.Provider + " | Test av fritidsbåtforsikring"}
      description={"Vurdering av kvalitet på vilkår og dekninger på " + data.fritidsbatforsikring2020Csv.Product + " fra " + data.fritidsbatforsikring2020Csv.Provider + ". Sammenlign "+data.fritidsbatforsikring2020Csv.Product + " fra " + data.fritidsbatforsikring2020Csv.Provider +  " med andre tilbydere av fritidsbåtforsikringer."}
      schema={`
            {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Bestforsikring.no",
              "item": "https://bestforsikring.no"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Vurdering av `+ data.fritidsbatforsikring2020Csv.Provider+`",
              "item": "https://bestforsikring.no/`+data.fritidsbatforsikring2020Csv.Provider+`"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Vurdering av fritidsbåtforsikringen `+data.fritidsbatforsikring2020Csv.Product+` fra `+data.fritidsbatforsikring2020Csv.Provider+`",
              "item": "https://bestforsikring.no/bat/fritidsbat/`+data.fritidsbatforsikring2020Csv.Provider+`/`+data.fritidsbatforsikring2020Csv.Product+`"
            }]
          }
       `}
    />
    <HeaderSmall siteSection="bat"/>
    <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled>
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
            <div className="krPW moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
              <div>
                <h3 style={{fontSize:'110%',paddingBottom:20}} className="moto-text_system_7"><Link to="/bat/fritidsbat">Fritidsbåtforsikringer</Link> > <> </>
                  {/*<Link to="/barn/2020">2020</Link> > <> </>*/}
                  <Link to={`/${slugifier(data.fritidsbatforsikring2020Csv.Provider)}#fritidsbat`}>{data.fritidsbatforsikring2020Csv.Provider}</Link> > <> </>
                  <Link to={`/bat/fritidsbat/${slugifier(data.fritidsbatforsikring2020Csv.Provider)}/${slugifier(data.fritidsbatforsikring2020Csv.Product)}`}>{data.fritidsbatforsikring2020Csv.Product}</Link>
                </h3>

                <h1>Vurdering av {data.fritidsbatforsikring2020Csv.Product} fra {data.fritidsbatforsikring2020Csv.Provider} (fritidsbåtforsikring)</h1>
                <p>
                  Hvor godt dekker forsikringen "{data.fritidsbatforsikring2020Csv.Product}"
                  som tilbys av <Link to={`/${slugifier(data.fritidsbatforsikring2020Csv.Provider)}#fritidsbat`}>{data.fritidsbatforsikring2020Csv.Provider}</Link>?
                  Hvor gode er vilkårene og hvor godt dekker denne fritidsbåtforsikringen ved skade?
                </p>
                <br/>
                <p style={{fontSize: '110%'}}>
                  {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne !== "0" && <>Forsikringen er vurdert til <strong><span style={{textDecoration:'underline'}}>{data.fritidsbatforsikring2020Csv.Defaqto_Stjerne} Defaqto stjerner</span></strong> av våre bransjeeksperter.</>}
                  {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne === "0" && <>Forsikringen er kun en grunnleggende dekning hvor forskjellen fra selskap til selskap er veldig liten. Defaqto har derfor ikke vurdert denne forsikringen.</>}
                </p>
                <br/>
                <p>
                  Vurderingen er faktabasert og baserer seg på
                  en grundig analyse av vilkår og dekningssummer.
                </p>
              </div>


              <div className="moto-spacing-top-medium">
                <h2>Hva er Defaqto-stjerner?</h2>
                <p>
                  En forsikring gis en vurdering  fra 1 til 5 Defaqto stjerner, hvor 5 stjerner er best.

                  {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne !== "0" &&
                  <>
                    &nbsp;Denne forsikringen fra {data.fritidsbatforsikring2020Csv.Provider} er, med sine <strong>{data.fritidsbatforsikring2020Csv.Defaqto_Stjerne} stjerner</strong>, vurdert til å være
                    {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne === "5" && <> <strong>blant de aller beste forsikringene</strong>.</>}
                    {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne === "4" && <> <strong>en veldig god forsikring</strong>.</>}
                    {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne === "3" && <> <strong>en god forsikring</strong>.</>}
                    {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne === "2" && <> <strong>en middels god forsikring</strong>.</>}
                    {data.fritidsbatforsikring2020Csv.Defaqto_Stjerne === "1" && <> <strong>en dårlig forsikring</strong>.</>}

                  </>
                  }
                </p>
                <br/>
                <p>
                  For å vurdere om forsikringen er god nok for ditt behov kan du ta en titt på vår <Link to={"/bat/fritidsbat/"+data.site.siteMetadata.guideyear+"/guide"}>guide til kjøp av fritidsbatforsikring</Link>.
                </p>
              </div>

              {/*<div className="moto-spacing-top-medium">
                <h2>Fakta om forsikringen:</h2>
                <p>

                </p>
              </div>*/}

              <div className="moto-spacing-top-medium">
                <h2>Finnes det bedre alternativer?</h2>
                <p>
                  Under er en oversikt over fritidsbåtforsikringer som er bedre eller like gode som "{data.fritidsbatforsikring2020Csv.Product}" fra {data.fritidsbatforsikring2020Csv.Provider}:
                </p>
                <br/>
                <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                  <ComparisonTable objList={data.table} siteSection={"bat/fritidsbat"} betterThan={parseInt(data.fritidsbatforsikring2020Csv.Defaqto_Stjerne)}/>
                </div>
              </div>

              <div className="moto-spacing-top-medium">
                <h2>Fakta om {data.fritidsbatforsikring2020Csv.Provider}:</h2>
                <p>
                  <Link to={`/${slugifier(data.fritidsbatforsikring2020Csv.Provider)}`}>{data.fritidsbatforsikring2020Csv.Provider}</Link> er et forsikringsselskap som tilbyr privatforsikringer i det norske markedet.
                </p>
              </div>


              <div className="moto-spacing-top-medium">
                <div data-widget-id="wid_1553425835_8mmnt722a" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                  <Link style={{marginLeft:20, marginRight:20, marginTop:20}} to={"/bat/fritidsbat/sammenlign"} data-action="page" className="moto-widget-button-link moto-size-medium moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">Sammenlign fritidsbåtforsikringer</span></Link>
                </div>
              </div>

            </div>

            {/*<div className="altBox">

            </div>*/}

          </div>
        </div>
      </div>
    </div>
  </Layout>
)

function slugifier(input) {

  let slugString = input;

  return slugString
    //.toLowerCase()
    .replace(/æ/g,'ae')
    .replace(/ø/g,'o')
    .replace(/å/g,'aa')
    .replace(/&/g,'og')
    .replace(/[^a-zA-Z0-9\/]+/g, '-')
    .replace(/\/-/g,'/')
    .slice(0, 96)                                             // Setting max length to 96
    .replace(/-$/g,'')
}